<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h1>Pracownik: {{ employee.user.name }}</h1>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <div class="input">
        <b-form-group>
          email
        </b-form-group>
        <b-form-input
          v-model="employee.user.email"
          type="text"
        />
      </div>
      <div class="input">
        <b-form-group>
          nazwa
        </b-form-group>
        <b-form-input
          v-model="employee.user.name"
          type="text"
        />
      </div>
      <div class="input">
        <b-form-group>
          numer telefonu
        </b-form-group>
        <b-form-input
          v-model="employee.user.phone_number"
          type="text"
        />
      </div>
      <div class="input">
        <b-form-group>
          Opis
        </b-form-group>
        <b-form-input
          v-model="employee.description"
          type="text"
        />
      </div>
      <div class="input">
        <b-form-group>
          Hasło
        </b-form-group>
        <b-form-input
          v-model="employee.user.password"
          type="password"
          placeholder="Jeśli chcesz zmienić hasło wpisz je tu..."
          class="password"
        />
      </div>
      <b-button
        variant="success"
        style="margin-top: 10px; width: 100%;"
        @click="submit()"
      >
        Zapisz zmiany
      </b-button>
      <router-link :to="{name: 'WorkHours-show', params: {id: this.$route.params.id}}">
        <b-button
          variant="primary"
          style="margin-top: 10px; width: 100%;"
        >
          grafik pracy pracownika
        </b-button>
      </router-link>
      <b-button
        class="btn btn-danger submit"
        variant="danger"
        @click="delete_user()"
      >
        usuń pracownika
      </b-button>
    </b-card>

    <b-card>
      <h3 class="text-center">
        Usługi
      </h3>
      <hr>
      <div
        v-for="offer in employee.offers"
        :key="offer"
        class="h4"
      >
        {{ offer.id }} - {{ offer.title }}
        <br>
        <hr>
      </div>
      <b-button
        v-b-modal.offersModal
        variant="primary"
        @click="fetchAllOffers()"
      >
        Edytuj usługi
      </b-button>
      <b-modal
        id="offersModal"
        title="Edytuj usługi"
      >
        <div
          v-for="offer in offers"
          :key="offer"
          class="d-flex justify-content-between"
        >

          <span style="width: 90%;">
            {{ offer.id }} - {{ offer.title }}
          </span>

          <span style="width: 10%;">
            <input
              v-model="offer.checked"
              type="checkbox"
            >
          </span>

          <br>
          <hr>
        </div>
        <b-button
          variant="primary"
          @click="addOffers()"
        >
          Zapisz zmiany
        </b-button>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BModal, BForm, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardText,
    BModal,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      employee: {},
      offers: [],
    }
  },
  mounted() {
    document.title = `Pracownik - ${this.$route.params.id} - Pickmode`
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    // get id from router
    const { id } = this.$route.params
    const apiUrl = process.env.VUE_APP_API_URL
    axios.get(`${apiUrl}employee/${id}`, config)
      .then(response => {
        this.employee = response.data
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 401) {
          this.$router.push('/error-404')
        }
      })
  },
  methods: {
    reload() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      // get id from router
      const { id } = this.$route.params
      const apiUrl = process.env.VUE_APP_API_URL
      axios.get(`${apiUrl}employee/${id}`, config)
        .then(response => {
          this.employee = response.data
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    submit() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      // get id from router
      const { id } = this.$route.params
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(`${apiUrl}employee/${id}`, {
        description: this.employee.description,
        name: this.employee.user.name,
        email: this.employee.user.email,
        password: this.employee.user.password,
        phone_number: this.employee.user.phone_number,
      }, config)
        .then(response => {
          swal.fire({
            title: 'Sukces',
            text: 'Zmiany zostały zapisane',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    delete_user() {
      // are u sure?
      swal.fire({
        title: 'Na pewno?',
        text: 'Tej akcji nie da się cofnąć!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, usuń tego pracownika!',
        cancelButtonText: 'Anuluj',
      }).then(result => {
        if (result.isConfirmed) {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
          // get id from router
          const { id } = this.$route.params
          const apiUrl = process.env.VUE_APP_API_URL
          axios.delete(`${apiUrl}employee/${id}`, config)
            .then(response => {
              swal.fire({
                title: 'Sukces',
                text: 'Pracownik został usunięty',
                icon: 'success',
                confirmButtonText: 'Ok',
              })
              this.$router.push('/Pracownicy')
            })
            .catch(error => {
              swal.fire({
                title: 'Error',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            })
        }
      }, dismiss => {
        // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
        if (dismiss === 'esc') {
          swal.fire({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }
      })
    },
    fetchAllOffers() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config)
        .then(response => {
          this.offers = response.data.success
          this.offers.forEach(offer => {
            offer.checked = false

            if (this.employee.offers.find(e => e.id === offer.id)) {
              offer.checked = true
            }
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    addOffers() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const { id } = this.$route.params
      const apiUrl = process.env.VUE_APP_API_URL
      const checked = this.offers.filter(offer => offer.checked)

      axios.put(`${apiUrl}employee/offer/${id}`, {
        offers: checked,
      }, config)
        .then(() => {
          swal.fire({
            title: 'Sukces',
            text: 'Usługi zostały zmienione',
            icon: 'success',
            confirmButtonText: 'Ok',
          })

          this.reload()
          // close modal
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },

  },
}
</script>

<style scoped>
.submit {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(204, 204, 204, 0.344);
  border-radius: 5px;
  background: rgba(204, 204, 204, 0.468);
  margin-top: 10px;
}
::placeholder {
    color: rgb(255, 255, 255);
}
</style>

</style>
